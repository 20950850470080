function subscribeEvent(eventName:any, listener:(data:{detail:any[] | any})=>void) {
    document.addEventListener(eventName, listener);
  }
  
  function unsubscribeEvent(eventName:string, listener:()=>void) {
    document.removeEventListener(eventName, listener);
  }
  
  function emitEvent(eventName:string, data:any[] | any) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }
  
  export { emitEvent, subscribeEvent, unsubscribeEvent};