import React from "react";

export default function ImageIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1797_10319)">
        <path
          d="M14.6668 8.00016C14.6668 11.1429 14.6668 12.7142 13.6905 13.6905C12.7142 14.6668 11.1429 14.6668 8.00016 14.6668C4.85747 14.6668 3.28612 14.6668 2.30981 13.6905C1.3335 12.7142 1.3335 11.1429 1.3335 8.00016C1.3335 4.85747 1.3335 3.28612 2.30981 2.30981C3.28612 1.3335 4.85747 1.3335 8.00016 1.3335"
          stroke="#6B6B6B"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M1.3335 8.33326L2.50122 7.31151C3.10873 6.77994 4.02435 6.81043 4.59515 7.38124L7.45497 10.2411C7.91312 10.6992 8.63432 10.7617 9.16443 10.3891L9.36323 10.2494C10.1261 9.71331 11.1581 9.77542 11.8511 10.3991L14.0002 12.3333"
          stroke="#6B6B6B"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.3745 1.95709L12.6526 1.67903C13.1133 1.21832 13.8603 1.21832 14.321 1.67903C14.7817 2.13974 14.7817 2.8867 14.321 3.34741L14.0429 3.62548M12.3745 1.95709C12.3745 1.95709 12.4093 2.54798 12.9307 3.06935C13.452 3.59072 14.0429 3.62548 14.0429 3.62548M12.3745 1.95709L9.81815 4.51347C9.645 4.68661 9.55843 4.77319 9.48397 4.86865C9.39614 4.98125 9.32084 5.10309 9.25941 5.232C9.20732 5.34129 9.16861 5.45744 9.09117 5.68974L8.84325 6.4335M14.0429 3.62548L11.4865 6.18185C11.3134 6.355 11.2268 6.44157 11.1314 6.51603C11.0187 6.60386 10.8969 6.67916 10.768 6.74059C10.6587 6.79268 10.5426 6.83139 10.3103 6.90883L9.5665 7.15675M9.5665 7.15675L9.08526 7.31716C8.97094 7.35527 8.8449 7.32551 8.75969 7.24031C8.67449 7.1551 8.64473 7.02906 8.68284 6.91474L8.84325 6.4335M9.5665 7.15675L8.84325 6.4335"
          stroke="#6B6B6B"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1797_10319">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
