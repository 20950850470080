export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{8,20}$/;
export const CONSTANTS = {
    BaseURL:"https://admin-be-staging-921982261919.us-central1.run.app/v1/",//"api.admin.treepz.com/v1/",
    Routes:{
      Login:"login",
      Otp:"otp",
      ForgotPassword:"forgot_password",
      Dashboard:"dashboard",
      TreepzHistory:"treepz-history",
      EmployeeData:"employee-data",
      CreatePassword:"create_password",
      Settings:"settings",
      Notification:"notification",
      Businesses:"businesses",
      Vehicles:"vehicles",
      RolesPermissions:"roles_and_permissions",
      CreateAccount:"create_account"
    },
    Events:{
      reloadBusiness:"reloadBusiness",
      reloadAllBusiness:"reloadAllBusiness",
      reloadVehicles:"reloadVehicles"
    },
    LocalStorage:{
      login:"user_login"
    }
}

interface ListProps {
icon?:JSX.Element;
title:string;
link:string;
}

export const DashboardNavItems:ListProps[] = [
  {title:"Dashboard",link:"/"+CONSTANTS.Routes.Dashboard},
  {title:"Businesses",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.Businesses},
  {title:"Treepz History",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.TreepzHistory},
  {title:"Vehicles",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.Vehicles},
  {title:"Roles and permissions",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.RolesPermissions},
] 
