/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEventHandler, RefObject, useEffect, useRef, useState } from 'react';
import { BaseButton, WhiteButton } from '../../../components/buttons';
import {CaretDownIcon, ExportIcon, FilterIcon, SettingsIcon } from '../icon';
import { Menu } from '../sections/HistoryTables/invitations';
import { CloseButton } from 'react-toastify/dist/components';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import Calendar from 'react-calendar';
import { CalendarComponent } from '../../../components/Calender';
import moment from 'moment';

interface SearchBarProps {
    calenderPosition: 'left' | 'right';
    onSearch: (v: string) => void;
    onAddBusiness: () => void;
    onImportBusiness: () => void;
    onExportPersonnel: () => void;
    page: 'data' | 'history';
    showFilter?: boolean;
    processing?: boolean;
    onFilterValue?: (values: { startDate: string; endDate: string }) => void;
}
export const HistorySearchBar = (props: SearchBarProps) => {
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [showColumn, setShowColumn] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const thisPopUp = useRef(null) as RefObject<HTMLSpanElement>;
    useEffect(() => {
        thisPopUp.current?.addEventListener('mouseleave', () => {
            //   setShowCalendar(false);
        });
        return () => { };
    }, [showCalendar]);
    return (
        <div className="row pb-3">
            <div className="col-4 d-flex align-items-center">
                {/* <WhiteButton 
    onClick={()=>{ }}
    >
    <span >Bulk action</span>
    <CaretDownIcon />
    </WhiteButton> */}
                <span ref={thisPopUp} className=" position-relative">
                    <div
                        className="filter-button-white"
                        onClick={() => {
                            setShowCalendar(!showCalendar);
                        }}
                    >
                        <span className="me-2">Filter</span>
                        <FilterIcon />
                    </div>
                    {showCalendar && (
                        <CalendarComponent
                            calenderPosition={props.calenderPosition}
                            onClose={() => {
                                setShowCalendar(false);
                            }}
                            startDate={moment().subtract(5, 'months').toISOString()}
                            onValue={({ startDate, endDate }) => {
                                if (props.onFilterValue) {
                                    props.onFilterValue({ startDate, endDate });
                                }
                                setShowCalendar(false);
                            }}
                        />
                    )}
                </span>
            </div>
            <div className={`col-8`}>
                <div className="d-flex align-items-center justify-content-end">
                    <WhiteButton
                        loading={props.processing}
                        onClick={props.onExportPersonnel}>
                        <ExportIcon />
                        <span className="ps-2">Export CSV</span>
                        <CaretDownIcon />
                    </WhiteButton>
                    {/* <Menu 
    list={[]}
    onValue={(d)=>{

    }}
    type="history"
    >
    <div
    className={"base-button-white d-flex align-items-center justify-centent-center"}
    >
<SettingsIcon 
color="black"
size={18}
/>
<span className="ps-2">Columns</span>
<CaretDownIcon />
    </div> 
    </Menu > */}
                </div>
            </div>
        </div>
    );
};


