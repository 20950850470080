import React from "react";
export default function TrashIcon() {
    return <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1665 4.99999H18.3332V6.66666H16.6665V17.5C16.6665 17.721 16.5787 17.933 16.4224 18.0892C16.2661 18.2455 16.0542 18.3333 15.8332 18.3333H4.1665C3.94549 18.3333 3.73353 18.2455 3.57725 18.0892C3.42097 17.933 3.33317 17.721 3.33317 17.5V6.66666H1.6665V4.99999H5.83317V2.49999C5.83317 2.27898 5.92097 2.06701 6.07725 1.91073C6.23353 1.75445 6.44549 1.66666 6.6665 1.66666H13.3332C13.5542 1.66666 13.7661 1.75445 13.9224 1.91073C14.0787 2.06701 14.1665 2.27898 14.1665 2.49999V4.99999ZM14.9998 6.66666H4.99984V16.6667H14.9998V6.66666ZM11.1782 11.6667L12.6515 13.14L11.4732 14.3183L9.99984 12.845L8.5265 14.3183L7.34817 13.14L8.8215 11.6667L7.34817 10.1933L8.5265 9.01499L9.99984 10.4883L11.4732 9.01499L12.6515 10.1933L11.1782 11.6667ZM7.49984 3.33332V4.99999H12.4998V3.33332H7.49984Z" fill="#B00020" />
    </svg>
}

export const CancelIcon = () => {
    return <svg fill="#000000" 
     width="24" height="24"
    viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier"> 
            <path d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM16 6c-5.522 0-10 4.478-10 10s4.478 10 10 10c5.523 0 10-4.478 10-10s-4.477-10-10-10zM20.537 19.535l-1.014 1.014c-0.186 0.186-0.488 0.186-0.675 0l-2.87-2.87-2.87 2.87c-0.187 0.186-0.488 0.186-0.675 0l-1.014-1.014c-0.186-0.186-0.186-0.488 0-0.675l2.871-2.869-2.871-2.87c-0.186-0.187-0.186-0.489 0-0.676l1.014-1.013c0.187-0.187 0.488-0.187 0.675 0l2.87 2.87 2.87-2.87c0.187-0.187 0.489-0.187 0.675 0l1.014 1.013c0.186 0.187 0.186 0.489 0 0.676l-2.871 2.87 2.871 2.869c0.186 0.187 0.186 0.49 0 0.675z"></path> 
            </g>
        </svg>
}