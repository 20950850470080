/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './../style.css';
import { GetRequest, PostRequest } from '../../../includes/functions';
import { BusinessProps } from '../../../includes/types';
import { BaseLoader } from '../../../components/baseloader';
import { HistorySearchBar } from '../components/HistorySearchBar';
import { ConfirmDialogComponent } from '../components/confirmDialog';
import { EditRoleComponent, EditUserComponent } from '../components/editUser';
import { AddBusinessComponent } from '../components/addPersonnel';
import { NoBusinessIcon } from '../../../assets/icons/NoBusinessIcon';
import { EyeOpen } from '../../../assets/icons/eye';
import { Menu } from './HistoryTables/invitations';
import ThreeVerticalDotsIcon from '../../../assets/icons/threeDots';
import moment from 'moment';
import { Pagination } from '../../../components/pagination';
import { toast } from 'react-toastify';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { CONSTANTS } from '../../../includes/constant';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import { BaseButton, WhiteButton } from '../../../components/buttons';

const ActiveList: React.FC<{
  activeAdmins: BusinessProps[];
  fetching: boolean;
  searchText: string;
  setSelectedBusiness: (business: BusinessProps) => void;
  setChangeRole: (business: BusinessProps) => void;
  setShowConfirmDialog: (show: boolean) => void;
}> = ({
  activeAdmins,
  fetching,
  searchText,
  setSelectedBusiness,
  setChangeRole,
  setShowConfirmDialog,
}) => (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Email Address</th>
          <th scope="col">Status</th>
          <th scope="col">Role</th>
          {/* <th scope="col"></th> */}
        </tr>
      </thead>
      <tbody>
        {fetching && (
          <tr>
            <td colSpan={10}>
              <BaseLoader /> <small className="fs-small">Fetching...</small>
            </td>
          </tr>
        )}
        {activeAdmins.length === 0 ? (
          <tr>
            <td colSpan={7}>
              <div className="text-center p-5">
                <NoBusinessIcon />
                <div className="fs-6 fw-bold my-3">No active admins yet</div>
                <WhiteButton
                  onClick={() => {
                    setShowConfirmDialog(true);
                  }}
                >
                  <span className="mx-2">Invite new admin</span>
                </WhiteButton>
              </div>
            </td>
          </tr>
        ) : (
          activeAdmins.map((admin, index) => (
            <tr key={admin._id}>
              <td>{index + 1}</td>
              <td>{admin.email}</td>
              <td>
              <td><div className={String(admin.status).toLowerCase().includes("unverified")?"status-suspended":"status-active"}>{String(admin.status).toLowerCase().includes("unverified")?"Unverified":admin.status}</div></td>
              </td>
              <td>{admin.role}</td>
              {/* <td>
              <Menu
                list={[]}
                type="role"
                onValue={(d) => {
                  if(d.action === "remove_admin")
                  {
                    setSelectedBusiness(admin);
                  }else{
                    setChangeRole(admin);
                  }
                }}
              >
                <EyeOpen />
              </Menu>
              </td> */}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );

const PendingInvitesList: React.FC<{
  pendingInvites: BusinessProps[];
  fetching: boolean;
  loadingItem: string;
  searchText: string;
  setSelectedBusiness: (business: BusinessProps) => void;
  CancelInvitation: (invite: BusinessProps) => void;
  ResendInvitation: (invite: BusinessProps) => void;
}> = ({
  pendingInvites,
  fetching,
  loadingItem,
  searchText,
  setSelectedBusiness,
  CancelInvitation,
  ResendInvitation,
}) => (
    <div className="main-scrollable p-5 pt-0">
      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Email Address</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {fetching && (
            <tr>
              <td colSpan={4}>
                <BaseLoader /> <small className="fs-small">Fetching...</small>
              </td>
            </tr>
          )}
          {pendingInvites.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <div className="text-center p-5">
                  <NoBusinessIcon />
                  <div className="fs-6 fw-bold my-3">No pending invites yet</div>
                </div>
              </td>
            </tr>
          ) : (
            pendingInvites.map((invite, index) => (
              <tr key={invite._id}>
                <td>{index + 1}</td>
                <td>{invite.email}</td>
                <td>
                  <div
                    className={`status ${invite.status === 'active'
                      ? 'active'
                      : invite.status === 'suspended'
                        ? 'suspended'
                        : invite.status === 'revoked'
                          ? 'revoked'
                          : invite.status === 'pending'
                            ? 'pending'
                            : ''
                      }`}
                  >
                    {invite.status}
                  </div>
                </td>
                <td style={{ width: 50 }}>
                  <div
                    style={{ width: 50 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {loadingItem === invite._id ? (
                      <BaseLoader />
                    ) : (
                      <Menu
                        onValue={(value) => {
                          if (value.action === 'resend') {
                            ResendInvitation(invite);
                          }
                          if (value.action === 'cancel') {
                            setSelectedBusiness(invite);
                          }
                        }}
                        type="data"
                      >
                        <ThreeVerticalDotsIcon />
                      </Menu>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

const RolesAndPermissionsSection: React.FC = () => {
  const [showAddBusiness, setShowAddBusiness] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [listOfBusiness, setListOfBusiness] = useState<BusinessProps[]>([]);
  const [pendingInvites, setPendingInvites] = useState<BusinessProps[]>([]);
  const [activeAdmins, setActiveAdmins] = useState<BusinessProps[]>([]);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'months').toISOString()
  );
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('activeAdmin');
  const [selectedBusiness, setSelectedBusiness] =
    useState<BusinessProps | null>(null);
    const [editAdminRole, setEditAdminRole] =
    useState<BusinessProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');
  const [loadingItem, setLoadingItem] = useState<string>('');
  const navigate = useNavigate();

  const handleExport = () => {
    DownloadCSV({ data: listOfBusiness, fileName: 'Business_list' });
    toast.success('Download was successful.', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const GetBusinesses = (page: number) => {
    setFetching(true);
    GetRequest(
      `employees?itemsPerPage=${pageSize}&pageNumber=${page}&startDate=${startDate}&endDate=${endDate}`,
      {},
      false
    )
      .then((res) => {
        setFetching(false);
        if (res.status && Array.isArray(res.data.employees)) {
          const BusinessList: BusinessProps[] = res.data
            .employees as BusinessProps[];
          const pendingData = BusinessList.filter(
            (a) => a.status === 'Pending'
          );
          const activeData = BusinessList.filter((a) => a.status !== 'Pending');

          setPendingInvites(pendingData);
          setActiveAdmins(activeData);
          setNumberOfActive(activeData.length);
          setNumberOfPending(pendingData.length);
          setListOfBusiness(BusinessList);
        }
      })
      .catch((error) => {
        setFetching(false);
        console.error('Error fetching businesses:', error);
      });
  };

  const UpdateUserRole = (values: any) => {
    setUpdating(true);
    PostRequest('role', values, true)
      .then((response) => {
        setUpdating(false);
        if (response.status) {
          GetBusinesses(currentPage);
          setShowEditUser(false);
        }
      })
      .catch((error) => {
        setUpdating(false);
        console.error('Error updating user:', error);
      });
  };

  const DeleteUser = (id: any) => {
    setLoading(true);
    PostRequest('delete:admin/user', { id: id }, true)
      .then((response) => {
        setLoading(false);
        if (response.status) {
          GetBusinesses(currentPage);
          setShowConfirmDialog(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error deleting user:', error);
      });
  };

  const CancelInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(`put:business/invite/cancel/${v._id}`, {}, true)
      .then((response) => {
        setLoadingItem('');
        if (response.status) {
          GetBusinesses(currentPage);
          setShowConfirmDialog(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error cancelling invitation', error);
      });
  };

  const ResendInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(
      `put:business/invite/resend`,
      {
        companyEmail: v.email,
      },
      true
    ).then((res) => {
      setLoadingItem('');
    });
  };
  useEffect(() => {
    GetBusinesses(currentPage);
    window.addEventListener('reloadActiveTable', (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetBusinesses(1);
        }, 1000);
      }
    });
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <div className="main-scrollable p-5 pt-0">
        <div className="heading mb-3">
          <b className="fCap me-3">Roles and permission</b>
        </div>
        <div className="d-flex">
          <HistorySearchBar
            calenderPosition={'right'}
            onSearch={(d) => {
              setFilterString(d);
            }}
            page="history"
            onAddBusiness={() => {
              setShowAddBusiness(true);
            }}
            processing={false}
            onExportPersonnel={() => {
              handleExport();
            }}
            onImportBusiness={() => {
              // setShowImportPersonnel(true)
            }}
            showFilter={true}
            onFilterValue={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />

          <BaseButton
            onClick={() => {
              setShowAddBusiness(true);
            }}
          >
            Invite admin
          </BaseButton>
        </div>
        <nav className="nav pl-c">
        <span
          onClick={() => {
            setActiveTab("activeAdmin");
            setSearchText('');
          }}
          className={`nav-link active btn p-2 mb-2 ${
            activeTab === "activeAdmin" ? "fw-bold selected-tab" : "fw-normal"
            }`}
          aria-current="page">
          Active Admin <span className={activeTab === 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfActive}</span>
        </span>
        <span
          onClick={() => {
            setActiveTab("pendingInvite");
            setSearchText('');
          }}
          className={`nav-link btn p-2 mb-2 ${
            activeTab === "pendingInvite" ? "fw-bold selected-tab" : "fw-normal"}`}>
          Pending Invite  <span className={activeTab !== 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfPending}</span> 
        </span>
      </nav>

        {activeTab === 'activeAdmin' ? (
          <ActiveList
            activeAdmins={activeAdmins}
            fetching={fetching}
            setSelectedBusiness={setSelectedBusiness}
            setChangeRole={(d)=>{
              setEditAdminRole(d)
            }}
            setShowConfirmDialog={setShowConfirmDialog}
            searchText={searchText}
          // setShowAddBusiness={setShowAddBusiness}
          />
        ) : (
          <PendingInvitesList
            pendingInvites={pendingInvites}
            fetching={fetching}
            loadingItem={loadingItem}
            searchText={searchText}
            setSelectedBusiness={setSelectedBusiness}
            CancelInvitation={CancelInvitation}
            ResendInvitation={ResendInvitation}
          />
        )}
        <Pagination
          onFilterRow={(d) => {
            setPageSize(d);
          }}
          onPage={(d) => {
            GetBusinesses(d);
            setCurrentPage(d);
          }}
        />
        {selectedBusiness && (
          <ConfirmDialogComponent
          title='Confirm action'
          message='Are you sure you want remove the admin?'
            onClose={() => {
              setSelectedBusiness(null);
            }}
            confirm={() => {
              DeleteUser(selectedBusiness);
              setSelectedBusiness(null);
            }}
          />
        )}

        {showConfirmDialog && (
          <ConfirmDialogComponent
            onClose={() => {
              setShowConfirmDialog(false);
            }}
            loading={loading}
            confirm={() => {
              DeleteUser(selectedBusiness?._id);
            }}
          />
        )}

        {editAdminRole && (
          <EditRoleComponent
            loading={updating}
            employeeData={editAdminRole}
            onClose={() => setEditAdminRole(null)}
            onValue={(values) => {
              UpdateUserRole(values);
            }}
          />
        )}

        {showAddBusiness && (
          <AddBusinessComponent onClose={() => setShowAddBusiness(false)} />
        )}
      </div>
    </div>
  );
};

export default RolesAndPermissionsSection;
