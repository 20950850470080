import React, { useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import { Formik} from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../../../includes/functions';
import { VehicleProps } from "../../../../includes/types";
import moment from "moment";
import { emitEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";
const schema = y.object({
  make:y.string().required("Vehicle make is required.").max(30,"Must be below 30 characters."),
  vehicleModel:y.string().required("Vehicle model is required.").max(10,"Must be below 11 characters."),
  year:y.string().required("Vehicle year is required.").max(11,"Must be below 12 characters."),
  registrationNumber:y.string().required("Vehicle registration number is required.").max(15,"Must be below 16 characters."),
  chasisNumber:y.string().required("Vehicle chassis number is required.").max(30,"Must be below 31 characters."),
  tag:y.string().required("Vehicle tag is required.").max(10,"Must be below 11 characters.")
    })
interface AddBusinessComponentprops {
onClose:()=>void;
details:VehicleProps;
}
export const EditVehicleComponent = (props:AddBusinessComponentprops)=>{
  const [loading,setLoading] = useState<boolean>(false)
  return  <div className="modal" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{borderRadius:20}}>
        <div className="modal-header">
          <h5 className="modal-title">Edit vehicle details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
<div className="modal-body p-3">
 <Formik
onSubmit={(values)=>{
  setLoading(true);
  const allowedValues = values;
  PostRequest("put:vehicle/"+props.details._id,allowedValues,true).then((res)=>{
    setLoading(false);
    if(res.status)
    {
      emitEvent(CONSTANTS.Events.reloadVehicles,{})
      if(props.onClose)
      {
        props.onClose()
      }
    }
  })
}}
validationSchema={schema}
initialValues={{
  make:props.details.make,
  vehicleModel:props.details.vehicleModel,
  year:props.details.year,
  registrationNumber:props.details.registrationNumber,
  chasisNumber:props.details.chasisNumber,
  tag:props.details.tag
}}

>
{({handleSubmit,setFieldValue,handleChange,errors,touched,values})=><div className='' >
      <div className="pt-3" >
        <BaseInput 
        label="Make"
        name='make'
        type='text'
        max={30}
        placeholder="e.g Toyota"
        onValueChange={handleChange("make")}  
        value={String(values.make).replace(/[ ]/g,'').trim()}
        required={true}
        error={touched.make && errors.make}
        />
         <BaseInput 
        label="Model"
        name='vehicleModel'
        type='text'
        max={10}
        placeholder="e.g Yaris"
        onValueChange={handleChange("vehicleModel")}  
        value={values.vehicleModel}
        required={true}
        error={touched.vehicleModel && errors.vehicleModel}
        />
         <BaseInput 
        label="Vehicle year"
        name='make'
        type='text'
        max={4}
        placeholder="e.g Wakanow Limited"
        onValueChange={(d)=>{
          const currentYear = parseInt(String(moment().format("YYYY")));
          const y = d.target.value.replace(/[a-z A-Z]/g,'');
          const max = parseInt(y) <= currentYear;
          const min = parseInt(y) > currentYear - 50;
          console.log("Min:"+min,"|","Max"+max);
          setFieldValue("year",y.toString().length === 4?min && max?y.toString():"":y.toString())// === 4?y <= currentYear?y:"":y)
          
        }}  
        value={String(values.year).replace(/[a-zA-Z]/,'')}
        required={true}
        error={touched.year && errors.year}
        />
         <BaseInput 
        label="Registration number"
        name='regnumber'
        type='text'
        max={15}
        placeholder="e.g GKL000000"
        onValueChange={handleChange("registrationNumber")}  
        value={String(values.registrationNumber).replace(/[ ]/g,'').trim()}
        required={true}
        error={touched.registrationNumber && errors.registrationNumber}
        />
         <BaseInput 
        label="Chassis number"
        name='chassis'
        type='text'
        max={30}
        placeholder="e.g Wakanow Limited"
        onValueChange={handleChange("chasisNumber")}  
        value={values.chasisNumber}
        required={true}
        error={touched.chasisNumber && errors.chasisNumber}
        />
        <BaseInput 
        label="Tag"
        name='tag'
        type='text'
        max={11}
        placeholder="Vehicle tag"
        onValueChange={handleChange("tag")}  
         value={String(values.tag).replace(/[ ]/g,'').trim()}
        required={true}
        error={touched.tag && errors.tag}
        />
        </div>
         <div className="row px-3">
        <BaseButton
        loading={loading}
        style={{minWidth:130}}
        onClick={handleSubmit}
        >Update vehicle</BaseButton>
      </div>
        </div>
       }
     </Formik>
        </div>
      </div>
    </div>
  </div>
}