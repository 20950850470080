// import { useLocation, useNavigate } from "react-router-dom";
import { RefObject, useEffect, useRef, useState } from 'react'
import { GoBackIcon } from '../../../assets/icons/BackIcon';
import { BoxIcon, CalendarIcon, CaretDownIcon } from '../icon'
import { BusinessProps, EmployeesProp, HistoryProps, UserProps, ChartDataProps } from '../../../includes/types';
import { Bar } from 'react-chartjs-2';
import { LightYellowButton, WhiteButton } from '../../../components/buttons';
import './../style.css';
import { CONSTANTS } from '../../../includes/constant';
import { TabProps } from './business';
import { BaseLoader } from '../../../components/baseloader';
import { GetRequest, PostRequest } from '../../../includes/functions';
import moment from 'moment';
import { NoBusinessIcon } from '../../../assets/icons/NoBusinessIcon';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import { toast } from 'react-toastify';
import { Pagination } from '../../../components/pagination';
import { emitEvent } from '../../../includes/customEventHook';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../../assets/icons/editIcon';
import { TrashIcon } from '../icon';
import { EyeOpen } from '../../../assets/icons/eye';
import { ChartBoard } from '../components/chartSection';
import { ConfirmDialogComponent } from '../components/confirmDialog';
import { EditUserComponent } from '../components/editUser';
import { Menu } from './HistoryTables/invitations';
import TreepzHistorySection from './treepz_history';
import ImageIcon from '../../../assets/icons/imageIcon';
import ThreeVerticalDotsIcon from '../../../assets/icons/threeDots';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import { AddEmployeeComponent } from '../components/addEmployee';
import { EmployeeBar } from '../components/employeeBar';
import { HistorySearchBar } from '../components/HistorySearchBar';
import { CalendarComponent } from '../../../components/Calender';
import { SearchBar } from '../components/searchBar';

interface BusinessDetailsSectionProp {
  _id: string;
}

export interface TabProp {
  class: string;
  title: string;
  amount: number;
}

const BusinessDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState<TabProps>('Business Information');
  const [businessDetails, setBusinessDetails] = useState<BusinessProps | null>(null);
  const [fetching, setFetching] = useState(false);
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get('id') || '';

  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="pb-3 d-flex align-items-center">
        <LightYellowButton
          to={`/${CONSTANTS.Routes.Dashboard}/${CONSTANTS.Routes.Businesses}`}
        >
          <GoBackIcon color="#F8B02B" size={15} />
          <span className="ps-2">Go back</span>
        </LightYellowButton>
        <div className="ps-5 mt-3 fw-bold">
          Businesses details (Wakanow Limited)
        </div>
      </div>

      <nav className="d-flex align-items-center justify-content-start mt-4 tabContainer">
        {[
          'Business Information',
          'Employee Data',
          // 'Treepz History',
          // 'Attached vehicles',
        ].map((tabName) => (
          <div
            key={tabName}
            onClick={() => setTab(tabName as TabProps)}
            className={tab === tabName ? 'tab-active' : 'tab'}
          >
            {tabName}
          </div>
        ))}
      </nav>

      <div className='mt-3'>
        {tab === 'Business Information' && <BusinessInformation _id={businessId} />}
        {tab === 'Employee Data' && <EmployeeData _id={businessId} id={''} employeeId={''} email={''} username={''} firstName={''} lastName={''} isCancelled={false} createdAt={''} __v={0} name={''} location={''} isActive={true} totalCheckIns={''} totalCheckIn={''} totalCheckOuts={''} totalCheckOut={''} VehicleLocation={''} vehicleTag={''} />}
        {tab === 'Treepz History' && <BusinessTreepz _id={businessId} />
        }
        {/* {tab === 'Attached vehicles' && (
          <div>
            <h3>s</h3>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BusinessDetails;

const BusinessInformation: React.FC<BusinessDetailsSectionProp> = ({ _id }) => {
  const [businessDetails, setBusinessDetails] = useState<BusinessProps | null>(
    null
  );
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      setFetching(true);
      try {
        const response = await GetRequest(`business/${_id}`, {});
        if (response.status) {
          setBusinessDetails(response.data.business);
        } else {
          console.warn('Failed to fetch business details:', response);
          setError('Failed to fetch business details.');
        }
      } catch (error) {
        console.error('Error fetching business details:', error);
        setError('Error occurred while fetching business details.');
      } finally {
        setFetching(false);
      }
    };

    if (_id) {
      fetchBusinessDetails();
    } else {
      console.warn('No _id provided. Skipping fetch operation.');
    }
  }, [_id]);

  // Filter administrators
  const administrators = businessDetails?.employees?.filter(
    (employee) => employee.role === 'Administrator'
  );

  return (
    <div className='mt-4'>
      <div className="light-gray-business">
        <div className="row gap-4 align-items-center p-9">
          <div className='col-1'>
            <center>
              <div className="avatar d-flex align-items-center justify-content-center position-relative">
              </div>
            </center>
          </div>
          <div className='col-10 d-flex'>
            <div className='col-3'>
              <p style={{ color: '#6F7174' }}>Business name</p>
              <small style={{ color: '#4D5154', fontWeight: 'bold' }}>{businessDetails?.name || 'N/A'}</small>
            </div>
            <div className='col-3'>
              <p style={{ color: '#6F7174' }}>Physical address</p>
              {/* <small>{businessDetails?.address || 'N/A'}</small> */}
              <small style={{ color: '#4D5154', fontWeight: 'bold' }}>Lekki Lagos</small>
            </div>
            <div className='col-3'>
              <p style={{ color: '#6F7174' }}>Email address</p>
              <small style={{ color: '#4D5154', fontWeight: 'bold' }}>{businessDetails?.email || 'N/A'}</small>
            </div>
            <div className='col-3'>
              <p style={{ color: '#6F7174' }}>Staff strength</p>
              <small style={{ color: '#4D5154', fontWeight: 'bold' }}>{businessDetails?.employees?.length || 'N/A'}</small>
            </div>
          </div>
        </div>
      </div>
      <div className='p-8'>
        <h5 style={{ color: '#4D5154', fontWeight: 'bold' }}>Administrator's details</h5>
        <div className="row mt-9">
          {businessDetails?.employees?.map((employee) => (
            <div key={employee._id} className="row mt-4">
              <div className="col">
                <p style={{ color: '#6F7174' }}>Full name</p>
                <small style={{ color: '#4D5154', fontWeight: 'bold' }}>{employee.firstName} {employee.lastName}</small>
              </div>
              <div className="col">
                <p style={{ color: '#6F7174' }}>Role in organization</p>
                <small style={{ color: '#4D5154', fontWeight: 'bold' }}>Employee</small>
              </div>
              <div className="col">
                <p style={{ color: '#6F7174' }}>Phone number</p>
                <small style={{ color: '#4D5154', fontWeight: 'bold' }}>+2348090100102</small>
              </div>
              <div className="col">
                <p style={{ color: '#6F7174' }}>Email address</p>
                <small style={{ color: '#4D5154', fontWeight: 'bold' }}>{employee.email}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const EmployeeData = ({ _id }: EmployeesProp) => {
  const [showAddPersonnel, setShowAddPersonnel] = useState<boolean>(false);
  const [employees, setEmployees] = useState<EmployeesProp[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const [listOfBusiness, setListOfBusiness] = useState<BusinessProps[]>([]);
  const [pendingInvites, setPendingInvites] = useState<BusinessProps[]>([]);
  const [activeEmployees, setActiveEmployees] = useState<BusinessProps[]>([]);
  const [tab, setTab] = useState<string>('employee');
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState<string>('');
  const [selectedEmployee, setSelectedEmployee] = useState<BusinessProps | null>(null);
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);

  const handleExport = () => {
    DownloadCSV({ data: listOfBusiness, fileName: 'BusinessEmployee_list' });
    toast.success('Download was successful.', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const fetchEmployees = (page: number) => {
    console.log("Starting to fetch employees...");
    setFetching(true);
    GetRequest(`business/${_id}`, {
      page: page,
      pageSize: pageSize,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    },
      false
    )
      .then((res) => {
        setFetching(false);
        if (res.status) {
          console.log('Business details fetched successfully:', res.data.business.employees);
          const BusinessList: BusinessProps[] = res.data.business.employees as BusinessProps[];
          const pendingData = BusinessList.filter((a) => a.status === 'Pending');
          const activeData = BusinessList.filter((a) => a.status !== 'Pending');
          setPendingInvites(pendingData);
          setActiveEmployees(activeData);
          setNumberOfActive(activeData.length);
          setNumberOfPending(pendingData.length);
          setListOfBusiness(BusinessList);
          setEmployees(res.data.business.employees);
        }
      })
      .catch((error) => {
        setFetching(false);
        console.error("Error fetching business:", error);
      });
  };

  const UpdateUser = (values: any) => {
    setUpdating(true);
    PostRequest('patch:admin/user', values, true)
      .then((response) => {
        setUpdating(false);
        if (response.status) {
          fetchEmployees(currentPage);
          setShowEditUser(false);
        }
      })
      .catch((error) => {
        setUpdating(false);
        console.error('Error updating user:', error);
      });
  };

  const DeleteUser = (id: any) => {
    setLoading(true);
    PostRequest('delete:admin/user', { id: id }, true)
      .then((response) => {
        setLoading(false);
        if (response.status) {
          fetchEmployees(currentPage);
          setShowConfirmDialog(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error deleting user:', error);
      });
  };

  const CancelInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(`put:business/invite/cancel/${v._id}`, {}, true)
      .then((response) => {
        setLoadingItem('');
        if (response.status) {
          fetchEmployees(currentPage);
          setShowConfirmDialog(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error cancelling invitation', error);
      });
  };

  const ResendInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(
      `put:business/invite/resend`,
      {
        companyEmail: v.email,
      },
      true
    ).then((res) => {
      setLoadingItem('');
    });
  };

  useEffect(() => {
    fetchEmployees(currentPage);
  }, [_id, currentPage]);

  const PendingInvitesTab = () => {
    return (
      <div className="">
        <table className='table table-responsive'>
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Employee ID</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Email Address</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {fetching && (
              <tr>
                <td colSpan={10}>
                  <BaseLoader /> <small className="fs-small">Fetching...</small>
                </td>
              </tr>
            )}
            {pendingInvites.map((a, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                {/* <td>{invite.employeeId}</td> */}
                <td>{a.name}</td>
                <td>{a.email}</td>
                {/* <td>{invite.totalCheckIns}</td> */}
                {/* <td>{invite.totalCheckOuts}</td> */}
                {/* <td>{moment(invite.dateJoined).format("YYYY-MM-DD")}</td> */}
                <td style={{ width: 50 }}>
                  <div
                    style={{ width: 50 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {loadingItem === a._id ? (
                      <BaseLoader />
                    ) : (
                      <Menu
                        onValue={(value) => {
                          if (value.action === 'resend') {
                            ResendInvitation(a);
                          }
                          if (value.action === 'cancel') {
                            setSelectedEmployee(a);
                          }
                        }}
                        type="data"
                      >
                        <ThreeVerticalDotsIcon />
                      </Menu>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedEmployee && (
          <ConfirmDialogComponent
            onClose={() => {
              setSelectedEmployee(null);
            }}
            confirm={() => {
              CancelInvitation(selectedEmployee);
              setSelectedEmployee(null);
            }}
          />
        )}
      </div>
    );
  }

  const ActiveEmployeeTab = () => {
    return (
      <div className="">
        <table className='table'>
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Employee ID</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Email Address</th>
              {/* <th scope="col">Total Check-Ins</th> */}
              {/* <th scope="col">Total Check-Outs</th> */}
              {/* <th scope="col">Date Joined</th> */}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fetching && (
              <tr>
                <td colSpan={10}>
                  <BaseLoader /> <small className="fs-small">Fetching...</small>
                </td>
              </tr>
            )}
            {activeEmployees.map((employee, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{employee._id}</td>
                <td>{`${employee.firstName} ${employee.lastName}`}</td>
                <td>{employee.email}</td>
                {/* <td>{employee.totalCheckIns}</td> */}
                {/* <td>{employee.totalCheckOuts}</td> */}
                {/* <td>{moment(employee.dateJoined).format("YYYY-MM-DD")}</td> */}
                <td>
                  <div className="row">
                    <div className="col-6">
                      <button
                        className="btn"
                        onClick={() => {
                          setSelectedEmployee(employee);
                          setShowEditUser(true);
                        }}
                      >
                        <EditIcon />
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() => {
                          setSelectedEmployee(employee);
                          setShowConfirmDialog(true);
                        }}
                        className="btn"
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showEditUser && (
          <EditUserComponent
            loading={updating}
            employeeData={selectedEmployee}
            onClose={() => setShowEditUser(false)}
            onValue={(values) => {
              UpdateUser(values);
            }}
          />
        )}
        {showConfirmDialog && (
          <ConfirmDialogComponent
            onClose={() => {
              setShowConfirmDialog(false);
            }}
            loading={loading}
            confirm={() => {
              DeleteUser(selectedEmployee?._id);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className='mt-3'>
      <div className="row">
        <div className="col-6">
          <nav className="nav pl-c">
            <span
              onClick={() => {
                setTab("employee");
                setSearchText('');
              }}
              className={`nav-link active btn p-2 mb-2 ${tab === "employee" ? "fw-bold selected-tab" : "fw-normal"
                }`}
              aria-current="page">
              Employees <span>({numberOfActive})</span>
            </span>
            <span
              onClick={() => {
                setTab("invited");
                setSearchText('');
              }}
              className={`nav-link btn p-2 mb-2 ${tab === "invited" ? "fw-bold selected-tab" : "fw-normal"}`}>
              Invited members  <span>({numberOfPending})</span>
            </span>
          </nav>
        </div>
        <div className="col-6">
          <EmployeeBar
            calenderPosition={'right'}
            onSearch={(d) => {
              setFilterString(d);
            }}
            page="history"
            onAddEmployee={() => {
              setShowAddPersonnel(true);
            }}
            processing={processing}
            onExportPersonnel={() => {
              handleExport();
            }}
            onImportBusiness={() => {
              setShowImportPersonnel(true)
            }}
          />
        </div>
      </div>

      <div>
        {tab === "employee" && <ActiveEmployeeTab />}
        {tab === "invited" && <PendingInvitesTab />}
      </div>
      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          fetchEmployees(d);
          setCurrentPage(d);
        }}
      />

      {showAddPersonnel && (
        <AddEmployeeComponent onClose={() =>
          setShowAddPersonnel(false)}
        />
      )}
      {showImportPersonnel && (
        <ImportPersonnelComponent onClose={() =>
          setShowImportPersonnel(false)}
        />
      )}
    </div>
  );
}

const BusinessTreepz: React.FC<BusinessDetailsSectionProp> = ({ _id }) => {
  const tableFilter: string[] = ["Combined", "Check-Ins", "Check-Outs"];
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false)
  const [chartCheckInData, setchartCheckInData] = useState<ChartDataProps>({
      data: [],
      labels: []
    }
  )
  const [chartCheckOutData, setchartCheckOutData] = useState<ChartDataProps>({
      data: [],
      labels: []
    }
  )
  const [searching, setSearching] = useState<boolean>(false)
  const [fetching, setFetching] = useState(false);
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false)
  const [showAddBusiness, setshowAddBusiness] = useState<boolean>(false)
  const [allEmployeesCount, setAllEmployeesCount] = useState<number>(0);
  const [totalEmployees, setTotalEmployees] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [totalCheckIn, setTotalCheckIn] = useState<number>(0);
  const [totalCheckOut, setTotalCheckOut] = useState<number>(0);
  const [historyList, setHistoryList] = useState<HistoryProps[]>([]);
  const [employeeDetails, setEmployeeDetails] = useState<HistoryProps | null>(null)
  const [filterString, setFilterString] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(tableFilter[0])
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const allEmployees = async () => {
    setLoading(true);
    try {
      const response = await GetRequest(`business/${_id}`, {});

      if (response.status) {
        console.log('API to get attendance that i know not of', response)
        const businessData: BusinessProps = response.data.business;
        if (businessData.employees) {
          setAllEmployeesCount(businessData.employees.length);
          setTotalEmployees(businessData.employees.length);
          console.log('Fetched Employees:', businessData.employees);
        }
      } else {
        console.warn('Failed to fetch business details:', response);
        setError('Failed to fetch business details.');
      }
    } catch (error) {
      console.error('Error fetching business details:', error);
      setError('An error occurred while fetching business details.');
    } finally {
      setLoading(false);
    }
  };

  const AllChartData = () => {
    setSearching(true)
    GetRequest("admin/attendence/report", {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    }, false).then((res) => {
      setSearching(false)
      var selectMonths: string[] = [];
      var countIntervalsCheckIn: number[] = [];
      var countIntervalsCheckOut: number[] = [];
      if (res.status) {
        console.log('API to get attendance that i know not of', res)
        res.data.forEach((a: any, i: number) => {
          const month = moment(a.checkIns[0]).format("MMM");
          selectMonths.push(month);
          countIntervalsCheckIn.push(a.checkIns.length);
          countIntervalsCheckIn.push(100);
          countIntervalsCheckOut.push(a.checkOuts.length);
          countIntervalsCheckOut.push(100);
        })
        setchartCheckInData({
          data: countIntervalsCheckIn,
          labels: selectMonths
        })
        setchartCheckOutData({
          data: countIntervalsCheckOut,
          labels: selectMonths
        })
      } else {
        const month = moment(startDate).format("MMM");
        setchartCheckInData({
          data: [0, 100],
          labels: [month]
        })
        setchartCheckOutData({
          data: [0, 100],
          labels: [month]
        })
      }
    })
  }

  const fetchBusinessTrips = async () => {
    setFetching(true);
    try {
      const response = await GetRequest(`business/${_id}`, {});
      if (response.status) {
        console.log('API to get attendance that i know not of', response)
        setHistoryList(response.data.business);
      } else {
        console.warn('Failed to fetch business details:', response);
        setError('Failed to fetch business details.');
      }
    } catch (error) {
      console.error('Error fetching business details:', error);
      setError('Error occurred while fetching business details.');
    } finally {
      setFetching(false);
    }
  };

  const AllCheckInCheckOut = async () => {
    try {
      const checkInResponse = await GetRequest("trip/check-in", {}, false);
      if (checkInResponse.status) {
        setTotalCheckIn(checkInResponse.data?.totalCheckIns || 0);
      }

      const checkOutResponse = await GetRequest("trip/check-out", {}, false);
      if (checkOutResponse.status) {
        setTotalCheckOut(checkOutResponse.data.totalCheckOuts || 0);
      }
    } catch (error) {
      console.error('Error fetching check-in/check-out data:', error);
    }
  };

  const DeleteUser = (id: string) => {
    PostRequest("delete:admin/user", {
      id: id
    }).then((res) => {
      if (res.status) {
        setHistoryList(historyList.filter((a, i) => a.employeeId !== id));
      }
    })
  }

  const handleExport = () => {
    DownloadCSV({ data: historyList, fileName: "history_list" });
    toast.success("Download was successful.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    if (_id) {
      allEmployees()
      fetchBusinessTrips();
      AllCheckInCheckOut();
      AllChartData();
      thisView.current?.addEventListener("mouseleave", () => {
        setShowCalendar(false);
      })
    } else {
      console.warn('No _id provided. Skipping fetch operation.');
    }
  }, [_id]);

  const tabs: TabProp[] = [
    { class: 'light-yellow-card', title: 'Total Employees', amount: allEmployeesCount },
    { class: "gray-card", title: "Total Check-ins", amount: totalCheckIn },
    { class: 'Pale-blue-card', title: "Total Check-outs", amount: totalCheckOut }
  ];

  const thisView = useRef() as RefObject<HTMLDivElement>

  function setShowImportBusiness(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div style={{ position: "relative" }}>
      <div className='main-scrollable p-5 pt-0' >
        <div className="row">
          <div className="col-3">
            <p className='fw-bold'>Activity Insight</p>
            <div
              ref={thisView}
              className='position-relative'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  setShowCalendar(!showCalendar);
                }}
              >
                <div className='bx'>
                  {searching ? <BaseLoader /> : <CalendarIcon />}
                  <span className='tx'>{moment(startDate).format("MMM DD, YYYY")} - {moment(endDate).format("MMM DD, YYYY")}</span>
                  <span className='caret'
                  >
                    <CaretDownIcon />
                  </span>
                </div>
              </div>
              {showCalendar && <CalendarComponent
                calenderPosition="left"
                onClose={() => {
                  setShowCalendar(false)
                }}
                startDate={moment().subtract(3, "M").format("MM-DD-YYYY")}
                onValue={({ startDate, endDate }) => {
                  setEndDate(endDate)
                  setStartDate(startDate)
                  setShowCalendar(false)
                  setTimeout(() => {
                    AllChartData()
                  }, 1000)
                }}
              />}
            </div>
          </div>
          <div className="col-1"></div>
          <div className='col-8' >
            <ChartBoard
              backgroundColor="white"
              tabs={tabs}
            />
          </div>
        </div>

        <div className='mt-5'>
          <div className='card' style={{ minHeight: 370, position: 'relative' }} >
            <div className='row p-3'>
              <div className='col-4' >
                <div className='chart-title'>TREEPZ HISTORY</div>
                <div className='chart-date'>{moment(startDate).format("MMM DD, YYYY")} - {moment(endDate).format("MMM DD, YYYY")}</div>
              </div>
              <div className='col-8 pe-3' >
                <div className='chart-tabs-container row'>
                  {tableFilter.map((a, i) => <div key={i}
                    onClick={() => {
                      setSelectedTab(a);
                    }}
                    className={`col-4 ${selectedTab === a ? "chart-tabs-active" : ""}`}>{a}</div>)}
                </div>
              </div>
            </div>
            <div className='px-3' style={{ position: 'relative', height: 360, overflow: "hidden" }} >
              {selectedTab === "Check-Ins" || selectedTab === "Combined" ? <div style={{ height: "100%" }}>
                <Bar
                  data={{
                    labels: chartCheckInData.labels,
                    datasets: [
                      {
                        label: "",
                        data: chartCheckInData.data,
                        backgroundColor: "#FA6533",
                        maxBarThickness: 30
                      }
                    ]
                  }}
                  color="red"
                />
              </div> : null}
              {selectedTab === "Check-Outs" || selectedTab === "Combined" ? <div style={{ position: 'absolute', left: 40, top: -18, width: 580, height: 370 }}>
                <Bar
                  options={
                    {
                      scales: {
                        x: {
                          display: selectedTab === "Check-Outs" ? true : false,
                        },
                        y: {
                          display: selectedTab === "Check-Outs" ? true : false,
                        }
                      }
                    }}
                  data={{
                    labels: chartCheckOutData.labels,
                    datasets: [
                      {
                        label: "",
                        data: chartCheckOutData.data,
                        backgroundColor: "#F8B02B",
                        maxBarThickness: 20,
                      }
                    ]
                  }}
                  color="red"
                />
              </div> : null}
            </div>
            <div className='d-flex align-items-center justify-content-end' style={{ position: "absolute", bottom: 10, left: 0, zIndex: 999999, width: "100%" }}>
              <div className='c-box-checkin'></div>
              <span className='px-2 cb-txt'>Check-ins</span>
              <div className='c-box-checkout'></div>
              <span className='px-2 cb-txt'>Check-outs</span>
            </div>
          </div>
        </div>

        <div className="row mt-3 fw-bold">
          <div className="col-3">
            <p>Treepz history</p>
          </div>
          <div className="col-9">
            <HistorySearchBar
              calenderPosition="right"
              onSearch={(d) => {

              }}
              page='history'
              onAddBusiness={() =>
                setshowAddBusiness(true)
              }
              processing={processing}
              onExportPersonnel={() => {
                handleExport()
              }}

              onImportBusiness={() =>
                setShowImportPersonnel(true)
              }
              showFilter={true}
              onFilterValue={({ startDate, endDate }) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
            />
          </div>
          <SearchBar
            calenderPosition="right"
            onSearch={(d) => {
              setSearchText(d);
            }}
            processing={processing}
            page="businesses"
            onAddBusiness={() =>
              setshowAddBusiness(true)
            }
            onExportPersonnel={() => {
              handleExport();
            }}
            onImportBusiness={() =>
              setShowImportBusiness(true)
            }
            showFilter={true}
            onFilterValue={({ startDate, endDate }) => {
            }}
          />
        </div>

        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">S. No.</th>
              <th scope="col">Check-In</th>
              <th scope="col">Check-Out</th>
              <th scope="col">Vehicle Name</th>
              <th scope='col'>Pick-up Location</th>
              <th scope='col'>Vehicle Location</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <BaseLoader /> <small className='fs-small'>Fetching...</small>
                </td>
              </tr>
            )}
            <td>Trrepz</td>
            {/* {filteredItems.map((item, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{item.checkInTime}</td>
              <td>{item.checkOutTime}</td>
              <td>{item.vehicleName}</td>
              <td>{item.pickupLocation}</td>
              <td>{item.vehicleLocation}</td>
              <td style={{ width: 50 }}>
              <Menu
                onValue={(value) => {
                  if (value.action === "delete") {
                    DeleteUser(item.employeeId)
                  }
                  if (value.action === "view") {
                    setEmployeeDetails(item)
                  }
                }}
                type='history'
              >
                <ThreeVerticalDotsIcon />
              </Menu>
              </td>
            </tr>
          ))} */}
          </tbody>
        </table>
      </div>
      <Pagination
        onFilterRow={(size) => setPageSize(size)}
        onPage={() => fetchBusinessTrips()}
      />
    </div>
  );
};

