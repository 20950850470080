import React, { useEffect, useState } from 'react';
import './../style.css';
import { SearchBar } from '../components/searchBar';
import { AddBusinessComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import ActiveTable from './HistoryTables/activeBusiness';
import InvitationTable from './HistoryTables/invitations';
import { GetRequest } from '../../../includes/functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GoBackIcon } from '../../../assets/icons/BackIcon';
import { CONSTANTS } from '../../../includes/constant';
import { LightYellowButton } from '../../../components/buttons';
import { BusinessProps } from '../../../includes/types';
import {
  emitEvent,
  subscribeEvent,
  unsubscribeEvent,
} from '../../../includes/customEventHook';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import BusinessDetails from './businessDetails';

export type TabProps =
  | 'Business Information'
  | 'Employee Data'
  | 'Treepz History'
  | 'Attached vehicles';

export default function BusinessesSection() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<string>('active');
  const [showAddBusiness, setShowAddBusiness] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showImportPersonnel, setShowImportBusiness] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [exportedList, setExportedList] = useState<BusinessProps[]>([]);

  const HandleExport = () => {
    DownloadCSV({ data: exportedList, fileName: 'business' });
    toast.success('Download was successful.', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    subscribeEvent(CONSTANTS.Events.reloadBusiness, (data) => {
      setTab('invited');
    });
    subscribeEvent(CONSTANTS.Events.reloadAllBusiness, ({ detail }) => {
      if (Array.isArray(detail.detail)) {
        const data: BusinessProps[] = detail.detail as BusinessProps[];
        setExportedList(data);
        setNumberOfActive(
          data.filter((a: BusinessProps, i: number) => a.status !== 'Pending')
            .length
        );
        setNumberOfPending(
          data?.filter((a: BusinessProps, i: number) => a.status === 'Pending')
            .length
        );
      }
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.reloadBusiness, () => {});
      unsubscribeEvent(CONSTANTS.Events.reloadAllBusiness, () => {});
    };
  }, [tab]);
  if (searchParams.get('id')) {
    return <BusinessDetails />;
  }

  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="heading mb-3">
        <b className="fCap">Businesses</b>
      </div>

      <SearchBar
        calenderPosition="left"
        onSearch={(d) => {
          setSearchText(d);
        }}
        processing={processing}
        page="businesses"
        onAddBusiness={() =>{
          setShowAddBusiness(true)
        }}
        onExportPersonnel={() => {
          HandleExport();
        }}
        onImportBusiness={() =>{ 
          setShowImportBusiness(true)}
        }
        showFilter={true}
        onFilterValue={({ startDate, endDate }) => {
          const sendData = { startDate, endDate };
          const reloadEvent = new CustomEvent(
            tab === 'active' ? 'reloadActiveTable' : 'reloadinvitedTable',
            { detail: sendData }
          );
          window.dispatchEvent(reloadEvent);
        }}
      />
      <nav className="nav pl-c">
        <span
          onClick={() => {
            setTab('active');
            setSearchText('');
          }}
          className={`nav-link active btn p-2 mb-2 ${
            tab === 'active' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
          aria-current="page">
          Active <span className={tab === 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfActive}</span>
        </span>
        <span
          onClick={() => {
            setSearchText('');
            setTab('invited');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'invited' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
        >
          Pending Invite <span className={tab !== 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfPending}</span>
        </span>
      </nav>

      {tab === 'active' ? (
        <ActiveTable
          searchText={searchText}
          onAddNewBusiness={() => setShowAddBusiness(true)}
        />
      ) : null}

      {tab === 'invited' ? 
      <InvitationTable 
      searchText={searchText} /> : null}
      {showAddBusiness && (
        <AddBusinessComponent 
        onClose={() => 
          setShowAddBusiness(false)} />
      )}
      {showImportPersonnel && (
        <ImportPersonnelComponent
          onClose={() => setShowImportBusiness(false)}
        />
      )}
    </div>
  );
}
