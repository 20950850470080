import React from "react";
import { TabProps } from "../../sections/dashboard";
interface ChartBoardProps {
    tabs: TabProps[];
    backgroundColor?: string;
}
export const ChartBoard = (props: ChartBoardProps) => {
    return <div className={`row ${!props.backgroundColor ? "light-gray-card" : ""} p-2 px-1 mt-3`} >
        {props.tabs.map((a, i) => <div className='col-3 p-1' key={i} >
            <div className={`card dx-card ${a.class} m-0 p-0`} style={{ borderWidth: 0 }}>
                <div className='row ps-3'>
                    <div className='col-12 p-3'>
                        <div className='t1' >{a.title}</div>
                        <div className='t2' >{a.amount}</div>
                        <div className='d-flex'>
                            <span className='gt'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 12L12 4M12 4H6.66667M12 4V9.33333" stroke="#3CC13B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                0%
                            </span>
                            <span className='fs-10 ps-2 pt-3'>FROM LAST MONTH</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>)}
    </div>
}