import React, { useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import { Formik } from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../../../includes/functions';
import { emitEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";

const schema = y.object({
  companyName: y.string().required("Company name is required;"),
  companyEmail: y.string().required("Company email is required.").email("A valid email address is required."),
})
interface AddBusinessComponentprops {
  onClose: () => void;
}
export const AddBusinessComponent = (props: AddBusinessComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false)
  return <div className="modal" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{ borderRadius: 20 }}>
        <div className="modal-header">
          <h5 className="modal-title">Invite business</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body p-3">
          <Formik
            onSubmit={(values) => {
              setLoading(true);
              PostRequest("business/invite", values, true).then((res) => {
                setLoading(false);
                if (res.status) {
                  emitEvent(CONSTANTS.Events.reloadBusiness, {})
                  if (props.onClose) {
                    props.onClose()
                  }
                }
              })
            }}
            validationSchema={schema}
            initialValues={{
              companyName: "",
              companyEmail: ""
            }}

          >
            {({ handleSubmit, setFieldValue, handleChange, errors, touched, values }) => <div className='' >
              <div className="pt-3" >
                <BaseInput
                  label="Company Name"
                  name='CompanyName'
                  type='text'
                  max={20}
                  placeholder="e.g Wakanow Limited"
                  onValueChange={handleChange("companyName")}
                  value={values.companyName}
                  required={true}
                  error={touched.companyName && errors.companyName}
                />
                <BaseInput
                  label="Company email"
                  name='email'
                  type='email'
                  max={50}
                  placeholder="Company email"
                  onValueChange={handleChange("companyEmail")}
                  value={String(values.companyEmail).replace(/[ ]/g, '').trim()}
                  required={true}
                  error={touched.companyEmail && errors.companyEmail}
                />
              </div>
              <div className="row px-3">
                <BaseButton
                  loading={loading}
                  style={{ minWidth: 130 }}
                  onClick={handleSubmit}
                >Send invitation
                </BaseButton>
              </div>
            </div>
            }
          </Formik>
        </div>
      </div>
    </div>
  </div>
}